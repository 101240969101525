import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../styles/page/learn_page.scss";

import { graphql } from "gatsby";
import CustomLink from "../components/CustomLink";
import {
  LearnFooterCTAComponent,
  LearnSliderComponent,
} from "../components/LearningHubComponents";
import { learnDetailHeaderSlider } from "../constants";

const VideoSlider = ({ data, pageContext }) => {
  const learning_hub_list =
    data?.body?.filter((x) => x.slice_type === "learning_hub_list") || [];
  const selected_list = learning_hub_list.find(
    (x) =>
      (x.items?.filter((xx) => xx.slug.text === pageContext.uid) || [])?.length
  );
  const index = selected_list?.items?.findIndex(
    (elem) => elem?.slug?.text === pageContext?.uid
  );
  const [currentIndex, setCurrentIndex] = useState(index || 0);
  const sliderRef = useRef();
  const _selected_list = Object.keys(selected_list)?.length
    ? selected_list
    : learning_hub_list[0];

  useEffect(() => {
    sliderRef.current.slickGoTo(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageContext?.uid]);

  return (
    <>
      <section className="w-full mx-auto max-w-7xl">
        <CustomLink
          to="/learning-hub/"
          className="text-md mt-4 flex cursor-pointer items-center mb-[-20px]"
        >
          <svg
            width="29"
            height="16"
            viewBox="0 0 29 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28 9C28.5523 9 29 8.55228 29 8C29 7.44772 28.5523 7 28 7V9ZM0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM28 7H1V9H28V7Z"
              fill="#8C8C8C"
            />
          </svg>
          <span className="ml-2 text-xl font-semibold text-gray-1200">
            Back{" "}
          </span>
        </CustomLink>

        <div className="max-w-7xl w-full mx-auto flex flex-col items-center text-center px-2.5 md:px-0">
          <span className="  font-semibold text-[#6060FC] text-base">
            {_selected_list?.items[currentIndex]?.tag?.text}
          </span>
          <h1 className="text-2xl md:text-4xl leading-9 text-purple-1100   font-bold md:pt-4 pt-2.5 pb-12">
            {_selected_list?.items[currentIndex]?.card_heading?.text}
          </h1>
        </div>
      </section>
      <article className="flex flex-col items-center justify-center w-full max-w-xs pb-8 mx-auto lg:max-w-7xl md:max-w-2xl lg:flex-row">
        <div
          className={`rounded-xl shadow-lg cursor-pointer lg:flex flex-col hidden ${
            currentIndex > 0 ? "lg:visible" : "h-[140px] w-[210px] lg:invisible"
          }`}
          onClick={() => {
            sliderRef.current.slickPrev();
          }}
        >
          {currentIndex > 0 && (
            <>
              <div className="h-[140px] w-[210px]">
                {_selected_list?.items[currentIndex - 1]?.thumbnail?.url ? (
                  <img
                    src={
                      _selected_list?.items[currentIndex - 1]?.thumbnail?.url
                    }
                    width={0}
                    height={0}
                    alt="_selected_list image"
                    className="h-full w-full rounded-t-xl bg-[#F8F8FF]"
                  />
                ) : (
                  <div className="h-full w-full rounded-t-xl bg-[#F8F8FF]" />
                )}
              </div>
              <button className="justify-center w-full p-3 text-base font-semibold text-gray-600">
                Watch previous
              </button>
            </>
          )}
        </div>
        <Slider
          ref={sliderRef}
          {...learnDetailHeaderSlider}
          className="learn-detail-slider"
          afterChange={(value) => {
            setCurrentIndex(value);
          }}
        >
          {_selected_list?.items?.map((slider, index) => {
            return (
              <div key={index}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: slider?.embedded_iframe?.html,
                  }}
                  className="loom-iframe h-[379px]"
                />
                <div className="bg-[#FAFAFA] text-purple-1000 lg:text-lg md:text-base text-sm font-normal   p-2.5 min-h-[130px] h-full">
                  {slider?.card_description?.text}
                </div>
              </div>
            );
          })}
        </Slider>
        <div
          onClick={() => {
            sliderRef.current.slickNext();
          }}
          className={`rounded-xl shadow-lg cursor-pointer lg:flex flex-col hidden ${
            currentIndex < _selected_list?.items?.length - 1
              ? "lg:visible"
              : "h-[140px] w-[210px] lg:invisible"
          }`}
        >
          {currentIndex < _selected_list?.items?.length - 1 && (
            <>
              <div className="h-[140px] w-[210px]">
                {_selected_list?.items[currentIndex + 1]?.thumbnail?.url ? (
                  <img
                    src={
                      _selected_list?.items[currentIndex + 1]?.thumbnail?.url
                    }
                    width={0}
                    height={0}
                    alt="_selected_list image"
                    className="h-full w-full rounded-t-xl bg-[#F8F8FF]"
                  />
                ) : (
                  <div className="h-full w-full rounded-t-xl bg-[#F8F8FF]" />
                )}
              </div>
              <button className="justify-center w-full p-3 text-base font-semibold text-gray-600">
                Watch next
              </button>
            </>
          )}
        </div>

        <div className="flex items-center justify-between w-full px-5 lg:hidden">
          <div
            onClick={() => {
              sliderRef.current.slickPrev();
            }}
            className="flex items-center lg:hidden"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.4362 8.10938H3.72288L11.9307 0.984375C12.0619 0.869531 11.9823 0.65625 11.8088 0.65625H9.7346C9.6432 0.65625 9.55648 0.689062 9.48851 0.747656L0.631476 8.43281C0.550356 8.50313 0.485298 8.59006 0.44071 8.68772C0.396123 8.78537 0.373047 8.89147 0.373047 8.99883C0.373047 9.10618 0.396123 9.21228 0.44071 9.30994C0.485298 9.4076 0.550356 9.49453 0.631476 9.56484L9.54007 17.2969C9.57523 17.3273 9.61741 17.3438 9.66194 17.3438H11.8065C11.9799 17.3438 12.0596 17.1281 11.9284 17.0156L3.72288 9.89062H17.4362C17.5393 9.89062 17.6237 9.80625 17.6237 9.70312V8.29688C17.6237 8.19375 17.5393 8.10938 17.4362 8.10938Z"
                fill="black"
                fill-opacity="0.45"
              />
            </svg>
            <span className="text-gray-600 font-semibold md:text-base text-xs   p-2.5 justify-center w-full">
              Watch previous
            </span>
          </div>

          <div
            className="flex items-center lg:hidden"
            onClick={() => {
              sliderRef.current.slickNext();
            }}
          >
            <span className="text-gray-600 font-semibold md:text-base text-xs   p-2.5 justify-center w-full">
              Watch next
            </span>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.563348 8.10938H14.2766L6.06882 0.984375C5.93757 0.869531 6.01725 0.65625 6.19069 0.65625H8.26491C8.35632 0.65625 8.44304 0.689062 8.511 0.747656L17.368 8.43281C17.4492 8.50313 17.5142 8.59006 17.5588 8.68772C17.6034 8.78537 17.6265 8.89147 17.6265 8.99883C17.6265 9.10618 17.6034 9.21228 17.5588 9.30994C17.5142 9.4076 17.4492 9.49453 17.368 9.56484L8.45944 17.2969C8.42429 17.3273 8.3821 17.3438 8.33757 17.3438H6.19304C6.0196 17.3438 5.93991 17.1281 6.07116 17.0156L14.2766 9.89062H0.563348C0.460223 9.89062 0.375848 9.80625 0.375848 9.70312V8.29688C0.375848 8.19375 0.460223 8.10938 0.563348 8.10938Z"
                fill="black"
                fill-opacity="0.45"
              />
            </svg>
          </div>
        </div>
      </article>
    </>
  );
};

const LearnDetailHeaderComponent = ({ data, ...rest }) => {
  return (
    <article className="pt-32 learn-detail-container md:pt-36">
      <VideoSlider data={data} {...rest} />
    </article>
  );
};

const LearnDetail = ({ data, pageContext }) => {
  const _data = data?.prismicLearningHub?.data || {};
  const learning_hub_list =
    _data?.body?.filter((x) => x.slice_type === "learning_hub_list") || [];
  return (
    <Layout>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          hreflang="en"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          hreflang="en"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>

      <section className="">
        <LearnDetailHeaderComponent data={_data} pageContext={pageContext} />
        {learning_hub_list?.map((item, index) => {
          return (
            <React.Fragment key={item?.id}>
              <LearnSliderComponent
                learning_hub_item={item}
                isDivider={index !== learning_hub_list?.length - 1}
              />
            </React.Fragment>
          );
        })}
        <LearnFooterCTAComponent
          heading={_data?.cta_header}
          description={_data?.cta_description}
        />
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    prismicLearningHub {
      data {
        body {
          ... on PrismicLearningHubDataBodyLearningHubList {
            id
            slice_type
            items {
              card_description {
                html
                text
              }
              card_heading {
                html
                text
              }
              tag {
                html
                text
              }
              thumbnail {
                url
              }
              video_time {
                text
              }
              embedded_iframe {
                html
              }
              slug {
                text
              }
            }
            primary {
              description {
                text
              }
              heading {
                html
                text
              }
            }
          }
        }
        meta_description {
          html
          text
        }
        meta_heading {
          html
          text
        }
        page_description {
          html
          text
        }
        page_heading {
          html
          text
        }
        cta_header {
          html
          text
        }
        cta_description {
          html
          text
        }
      }
    }
  }
`;

export const Head = ({ data, pageContext }) => {
  const _data = data?.prismicLearningHub?.data || {};
  const learning_hub_list =
    _data?.body?.filter((x) => x.slice_type === "learning_hub_list") || [];

  const selected_list = learning_hub_list.find(
    (x) =>
      (x.items?.filter((xx) => xx.slug.text === pageContext.uid) || [])?.length
  );

  const index = selected_list?.items?.findIndex(
    (elem) => elem?.slug?.text === pageContext?.uid
  );

  const _selected_list = Object.keys(selected_list)?.length
    ? selected_list
    : learning_hub_list[0];

  return (
    <SEO
      title={`${_selected_list?.items[index].card_heading.text} | Encord`}
      description={
        _selected_list?.items[index].card_description.text ||
        _data?.mata_description?.text
      }
      image="https://images.prismic.io/encord/295ddcea-8ef9-4d08-94f0-e82113bb491e_Learning+Hub+page.png?auto=compress,format"
      url="https://encord.com/learning-hub-detail/"
    />
  );
};

export default LearnDetail;
